.pagination-container {
    display: flex;
    list-style-type: none;
    margin-top: 30px;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
}
.pagination-container .pagination-item {
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: #f28920;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01071em;
    border-radius: 99px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
}

.pagination-container .pagination-item .arrow {
    background-color: #f28920;
    border-radius: 99px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-container .pagination-item .arrow svg{
    color: var(--white);
}

.pagination-container .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}
.pagination-container .pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}
.pagination-container .pagination-item.selected {
    background-color: #f28920;
    color: var(--white);
}


.pagination-container .pagination-item.disabled{
    pointer-events: none;
}

.pagination-container .pagination-item.disabled .arrow {
    background-color: #797a7c;
}

.pagination-container .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}
